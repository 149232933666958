
import $app from '@/plugins/modules';
import { IManipulationType } from '@/modules/clinic/types';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { IPatient, ITooth } from '../types';

@Component
export default class ToothCureDialog extends Vue {
  @Prop({ default: () => false })
  readonly value: boolean | undefined;

  @Prop({ default: () => false })
  readonly processing: boolean | undefined;

  @Prop({ default: () => null })
  readonly tooth: ITooth | undefined;

  @Prop({ default: () => null })
  readonly patient: IPatient | undefined;

  @Prop({ default: () => 0 })
  readonly startTab: number | undefined;

  tab = 0;
  manipulationsListVisible = false;
  manipulations: Array<IManipulationType> = [];
  recommendedList: Array<IManipulationType> = [];

  @Watch('value')
  onShow() {
    if (this.value) {
      this.tab = this.startTab || 0;
      this.manipulations = [];
      this.loadRecommended();
    }
  }

  get item(): ITooth | null {
    return this.tooth || null;
  }

  get visible(): boolean {
    return this.value || false;
  }

  set visible(value: boolean) {
    this.$emit('input', value);
  }

  get excluded() {
    const exclude = {};
    this.manipulations.forEach(d => Vue.set(exclude, d.code, true));
    return exclude;
  }

  get recommended(): Array<IManipulationType> {
    const filter: any = this.excluded;
    return this.recommendedList.filter(m => !filter[m.code]);
  }

  get total(): number {
    let result = 0;
    this.manipulations.forEach(m => { result += m.price || 0 })
    return result;
  }

  async loadRecommended() {
    if (this.tooth) {
      const index: any = {};
      const result: Array<IManipulationType> = []
      for (let i = 0; i < this.tooth.damages.length; i++) {
        const id = this.tooth.damages[i].damageId;
        if (id) {
          const manipulations: Array<IManipulationType> = await $app.get('/api/clinics/damages/' + id + '/manipulations');
          manipulations.forEach(m => {
            if (!index[m.code] && m.active) {
              index[m.code] = true;
              result.push(m);
            }
          });
        }
      }

      this.recommendedList = result;
    }
  }

  store() {
    this.$emit('store', this.manipulations)
  }

  add() {
    this.manipulationsListVisible = true;
  }

  select(manipulation: IManipulationType) {
    this.manipulations.push(manipulation);
  }

  remove(manipulation: IManipulationType) {
    const index = this.manipulations.findIndex(m => m.code === manipulation.code);
    this.manipulations.splice(index, 1);
  }

  clone(manipulation: IManipulationType) {
    this.manipulations.push($app.clone(manipulation));
  }

  transfer(manipulation: IManipulationType) {
    this.manipulations.push(manipulation);
  }
}
